<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.purchasePaymentRequest"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex">
                  <vx-tooltip
                    v-if="$store.getters['user/hasPermissions']('view')"
                    text="Show Invoice"
                    class="mr-4"
                  >
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Print Invoice" class="mr-4">
                    <vs-button
                      type="line"
                      color="green"
                      icon-pack="feather"
                      icon="icon-file"
                      v-on:click.stop="doDownloadPdf(tr)"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </vs-td>
            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td> ({{ tr.SupplierCode }}) {{ tr.SupplierName }} </vs-td>
            <vs-td>
              {{
                tr.BankName +
                " - " +
                tr.AccountBranch +
                " (" +
                tr.AccountNumber +
                " " +
                tr.AccountName +
                ") "
              }}
            </vs-td>
            <vs-td>
              {{
                tr.ToBankName +
                " - " +
                tr.ToAccountBranch +
                " (" +
                tr.ToAccountNumber +
                " " +
                tr.ToAccountName +
                ") "
              }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.Total) }} ({{ tr.CurrencyExchangeCode }})<br />
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="doByPassDownloadPdf"
        @cancel="closeDetail"
        @close="closeDetail"
        :accept-text="'Yes'"
        :cancel-text="'No'"
        :buttons-hidden="false"
        :active.sync="confirmation"
      >
        <div class="con-exemple-prompt">
          Re-Print {{ selectedData.Code }}?
          <br />
          <br />
          <br />
          <vs-textarea v-model="notes" />
        </div>
      </vs-prompt>

      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="doDownloadPdf(selectedPrint)"
        @cancel="() => (this.exchange = false)"
        @close="() => (this.exchange = false)"
        :accept-text="'Yes'"
        :cancel-text="'No'"
        :buttons-hidden="false"
        :active.sync="exchange"
      >
        <div class="con-exemple-prompt prompt-xs">
          Old Exchange : {{ priceFormat(oldExchangeRate) }}
          <br />
          <br />
          New Exchange : {{ priceFormat(newExchangeRate) }}
        </div>
      </vs-prompt>

      <vs-prompt
        title="Information"
        color="primary"
        @close="closeDetail"
        :buttons-hidden="true"
        :active.sync="approvalPrompt"
      >
        <div class="con-exemple-prompt prompt-md" style="max-height: 100%">
          <div v-if="this.selectedData.Type !== 4">
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Code Internal"
                  name="Code Internal"
                  v-model="purchaseInvoice.CodeInternal"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Code External"
                  name="Code External"
                  v-model="purchaseInvoice.Code"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Item Value"
                  name="Item Value"
                  v-model="itemValue"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Tax Value"
                  name="Tax Value"
                  v-model="taxValue"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total"
                  name="Total"
                  v-model="total"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Paid"
                  name="Total Paid"
                  v-model="totalPaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Unpaid"
                  name="Total Unpaid"
                  v-model="totalUnpaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="No Shipment"
                  name="No Shipment"
                  v-model="purchaseInvoice.DeliveryOrderNumber"
                  readonly
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Supplier Name"
                  name="Vendor"
                  :value="cnSupplier.supplier_name"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Supplier Code"
                  name="Supplier Code"
                  :value="cnSupplier.supplier_code"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Debit"
                  name="Total Debit"
                  :value="
                    priceFormat(
                      cnSupplier.total_debit ? cnSupplier.total_debit : 0
                    )
                  "
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Credit"
                  name="Total Credit"
                  :value="
                    priceFormat(
                      cnSupplier.total_credit ? cnSupplier.total_credit : 0
                    )
                  "
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Reference"
                  name="Reference"
                  :value="cnSupplier.reference"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total"
                  name="Total"
                  v-model="total"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Paid"
                  name="Total Paid"
                  v-model="totalPaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Unpaid"
                  name="Total Unpaid"
                  v-model="totalUnpaid"
                  readonly
                />
              </div>
              <!-- <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="No Shipment"
                  name="No Shipment"
                  v-model="purchaseInvoice.DeliveryOrderNumber"
                  readonly
                />
              </div> -->
            </div>
          </div>

          <!-- <vs-divider style="width: 100%;"><b>Charge</b></vs-divider>
        <div class="vx-row ">
          <vs-table class="w-full" stripe border description :sst="true" :data="dataCharge">
            <template slot="thead">
              <vs-th>Charge Name</vs-th>
              <vs-th>Value</vs-th>
              <vs-th>Tax</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{
                      data[indextr].charge_name.replace(/-/g, " ")
                    }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].value }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].tax }}</span>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div> -->
          <vs-divider style="width: 100%"><b>Payment</b></vs-divider>
          <vs-table
            stripe
            border
            description
            class="w-full"
            :sst="true"
            :data="dataPayment"
            max-height="400px"
          >
            <template slot="thead">
              <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
              <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
              <vs-td style="text-align: right"><b>Paid</b></vs-td>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                :class="data[indextr].class"
              >
                <vs-td
                  style="text-align: center"
                  :data="data[indextr].BankName"
                >
                  {{
                    data[indextr].BankID == 0
                      ? data[indextr].Type == 2
                        ? "DN"
                        : "-"
                      : data[indextr].BankName +
                        " " +
                        data[indextr].AccountNumber
                  }}
                </vs-td>
                <vs-td style="text-align: center">
                  {{ data[indextr].ReferenceCode }}
                </vs-td>
                <vs-td style="text-align: right">
                  {{
                    data[indextr].PaidInvoiceValue.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div
            @detailPurchaseInvoice="handleDetailPurchaseInvoice"
            @close="handleClose"
            :selected="selectedData"
          />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";

import moment, { now } from "moment";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
    paymentDate: {
      type: Object,
      default: () => ({}),
    },
    bankFrom: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      checked: [],
      checkedCode: [],
      checkedAll: false,
      confirmation: false,
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "Bank From",
          value: "bank_name",
        },
        {
          text: "Bank To",
          value: "to_bank_name",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      itemValue: 0,
      totalPaid: 0,
      total: 0,
      totalUnpaid: 0,
      totalDiscount: 0,
      dpp: 0,
      taxValue: 0,
      purchaseInvoice: {},
      dataCharge: [],
      dataPayment: [],
      dataPaymentProposal: [],
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      selectedPrint: {},
      oldExchangeRate: 0,
      newExchangeRate: 0,
      newExchangeRateID: 0,
      newExchange: [],
      totalNew: 0,
      exchange: false,
      cnSupplier: {},
    };
  },
  computed: {},
  watch: {
    paymentDate() {
      this.reloadData(this.params);
    },
    bankFrom() {
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    handleDetailPurchaseInvoice(id) {
      this.approvalPrompt = true;
      this.$vs.loading();
      const url =
        this.selectedData.Type === 4
          ? "/api/v1/credit-note-supplier/line-for-proposal/"
          : "/api/v1/pi/payment-proposal-line/";
      this.$http.get(url + id).then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          if (this.selectedData.Type === 4) {
            this.cnSupplier = resp.data.credit_note_supplier;
            this.totalPaid = resp.data.total_paid
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.totalUnpaid = resp.data.unpaid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            this.purchaseInvoice = resp.data.purchase_invoice;
            this.dataCharge = resp.data.data_charge;
            this.dataPayment = resp.data.detail_payment;
            this.dataPaymentProposal = resp.data.payment_proposal_proses;
            this.totalPaid = resp.data.paid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.itemValue = resp.data.item_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_invoice_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            const whTax = resp.data.data_line.reduce((acc, item) => {
              if (this.purchaseInvoice.IsSkb === 1) {
                return acc;
              } else {
                return acc + item.wh_tax;
              }
            }, 0);

            this.totalUnpaid = (resp.data.unpaid_value - whTax)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.taxValue = resp.data.tax_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.totalDiscount = resp.data.total_discount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val.payment_code)) {
        this.checked.splice(this.checked.indexOf(val.id), 1);
        this.checkedCode.splice(this.checkedCode.indexOf(val.id), 1);
      } else {
        this.checked.push(val.id);
        this.checkedCode.push(val.id);
      }
    },
    addAllChecked() {
      console.log(this.table.data);
      console.log(this.checkedAll);
      if (this.checkedAll == false) {
        for (var i = 0; i < this.table.data.length; i++) {
          console.log(this.table.data[i].id);
          if (this.checked.includes(this.table.data[i].id)) {
            //
          } else {
            this.checked.push(this.table.data[i].id);
            this.checkedCode.push(this.table.data[i].id);
          }
        }
      } else {
        for (var j = 0; j < this.table.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.table.data[j].id), 1);
          this.checkedCode.splice(
            this.checkedCode.indexOf(this.table.data[j].id),
            1
          );
        }
      }
      // this.checkedAll = null;
      console.log(this.checkedAll);
    },
    getBase64Image(url) {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        // console.log(dataURL)
      };
      img.src = url;
      return img;
    },
    terbilang(angka) {
      const text = angkaTerbilang(angka);
      const word = text.split(" ");
      for (var i = 0; i < word.length; i++) {
        word[i] = word[i].charAt(0).toUpperCase() + word[i].slice(1);
      }

      //Join all the elements of the array back into a string
      //using a blankspace as a separator
      const new_text = word.join(" ");
      return new_text;
    },
    dateFormat(date) {
      console.log(date);
      if (date) {
        return moment(date).format("DD-MM-YYYY");
      }
    },
    doByPassDownloadPdf() {
      console.log("doByPassDownloadPdf");
      let selected = this.selectedData;
      if (selected.CountPrint > 0 && this.notes == "") {
        this.$vs.notify({
          title: "Error",
          text: "notes required ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.confirmation = true;
        return;
      }

      this.$vs.loading();

      console.log(this.$userLogin.company_logo);
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + selected.ID)
        .then((result) => {
          try {
            const params = {
              notes: this.notes,
              clearing_date: moment().format("YYYY-MM-DD"),
              currency_exchange_rate_id: this.newExchangeRateID,
              currency_exchange_rate: this.newExchangeRate,
              total_new: this.totalNew,
            };
            const company = result.data.company;
            const supplier = result.data.supplier;
            const paymentRequest = result.data.purchasePaymentRequest;
            const createdAt = this.dateFormat(paymentRequest.CreatedAt);
            let date = moment().format("DD-MM-YYYY");
            const purchaseInvoice = result.data.purchaseInvoice;

            let supplierName = "";
            let supplierAddress = "";
            if (supplier.Code == "ONETIME") {
              supplierName = paymentRequest.SupplierName;
              purchaseInvoice.map((v, i) => {
                supplierAddress =
                  v.purchaseInvoice.SupplierAddress.toUpperCase() +
                  "\n  " +
                  v.purchaseInvoice.SupplierCity.toUpperCase();
              });
            } else {
              supplierName = supplier.Name;
              supplierAddress =
                supplier.Address.toUpperCase() +
                "\n  " +
                supplier.SubDistrict.toUpperCase() +
                ", " +
                supplier.District.toUpperCase() +
                "\n  " +
                supplier.City.toUpperCase() +
                ", " +
                supplier.Province.toUpperCase();
            }
            // this.$vs.loading.close();
            // result.data.purchasePaymentProposal.map( v => {
            //   this.paymentProposal.push(v)
            // })
            // this.notes = this.selected.Notes.toString().split(";")

            // const data = this.getBase64Image(this.$userLogin.company_logo)
            const doc = new jsPDF();
            // console.log(doc.getFontList())
            // resp.data.map((v,i) => {
            // let fontSize = 20
            // doc.setFont("times").setFontSize(fontSize,'bold')//.setFontStyle("bold");
            // doc.text('SUMMARY PAYMENT REQUEST ',100, 10,{ align:'center'}); //(text,x,y)
            // fontSize = 10
            // doc.setFont("times").setFontSize(fontSize)//.setFontStyle("bold");
            // doc.text('Transfer From',10, 30, ); //(text,x,y)
            // doc.text('Bank Name',10, 40, ); //(text,x,y)
            // doc.text(': ' + selected.BankName,40, 40, ); //(text,x,y)
            // doc.text('Account Number',10, 45, ); //(text,x,y)
            // doc.text(': ' + selected.AccountNumber,40, 45, ); //(text,x,y)
            // doc.setFont("times").setFontSize(fontSize,'bold')//.setFontStyle("bold");
            // doc.text('Transfer To',110, 30) //(text,x,y)
            // doc.setFont("times").setFontSize(fontSize)//.setFontStyle("bold");
            // doc.text('Bank Name',110, 40); //(text,x,y)
            // doc.text(': ' + selected.ToBankName,150, 40); //(text,x,y)
            // doc.text('Account Number',110, 45); //(text,x,y)
            // doc.text(': ' + selected.ToAccountNumber,150, 45); //(text,x,y)
            // doc.text('Vendor',110, 50); //(text,x,y)
            // doc.text(': ' + selected.ToAccountName,150, 50); //(text,x,y)
            // doc.text('For Payment',10, 70) //(text,x,y)
            // doc.text('Invoice Number',10, 80) //(text,x,y)
            // doc.text('Invoice Value',120, 80,{ align:'right'}) //(text,x,y)
            // doc.text('Voucher Number',150, 80) //(text,x,y)

            // let y = 85
            // let total = 0
            // result.data.purchasePaymentProposal.map((v,i) => {
            //       doc.text(v.PurchaseInvoiceCode,10, y) //(text,x,y)
            //       total += v.PaidValue
            //       doc.text(parseFloat(v.PaidValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),120, y,{ align:'right'}) //(text,x,y)
            //       doc.text(selected.Code,150, y) //(text,x,y)
            //       y += 5
            //       // y tidak boleh lebih dari 230 // kalau lebih nanti handle add page

            // })
            // y += 5
            // doc.text("Total ",10, y) //(text,x,y)

            // doc.text(parseFloat(total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),120, y,{ align:'right'}) //
            // autoTable(doc, {

            //   startY: y + 10,
            //   head: [],
            //   body: [['Terbilang', ': ' + angkaTerbilang(total).toUpperCase()]],
            //   // theme: 'grid',
            //   didDrawCell: function (data) {

            //   },
            // })
            // y = y + 10
            // doc.text('Prepare by',10, 250) //(text,x,y)
            // doc.text(this.$userLogin.name,10, 270) //(text,x,y)
            // doc.text('Acknowledge by',60, 250) //(text,x,y)
            // doc.text('Approve 1 by',110, 250) //(text,x,y)
            // doc.text('Approve 2 by',160, 250) //(text,x,y)
            // console.log(v,i, 'ini')
            // doc.addPage()

            let fontSize = 20;
            // if (i) {
            //   doc.addPage()
            // }
            doc.setFont("times").setFontSize(fontSize, "bold"); //.setFontStyle("bold");
            // doc.addImage(data,'PNG', 10, 10, 20, 20)
            // doc.text(27, 23, this.$userLogin.company_name);
            // doc.line(10, 30, 200, 30); // horizontal line
            // doc.text(80, 40, 'TRANSFER ORDER'); //(text,x,y)
            doc.text("VENDOR PAYMENT REQUEST ", 100, 10, { align: "center" }); //(text,x,y)

            fontSize = 10;

            doc.setFont("times").setFontSize(fontSize, "bold"); //.setFontStyle("bold");
            if (selected.CountPrint > 0) {
              doc.text(
                "Copy no " + selected.CountPrint + " ( printed :" + date + " )",
                100,
                18,
                { align: "center" }
              ); //(text,x,y)
            } else {
              doc.text("Original " + " ( printed :" + date + " )", 100, 13, {
                align: "center",
              }); //(text,x,y)
            }
            let header = [];
            header.push([
              "Payer Name",
              ": " + company.Name,
              "Payment Creation Date",
              ": " + createdAt,
            ]);
            header.push([
              "Payer Address",
              ": " +
                company.Address.toUpperCase() +
                "\n  " +
                company.SubDistrict.toUpperCase() +
                ", " +
                company.District.toUpperCase() +
                "\n  " +
                company.City.toUpperCase() +
                ", " +
                company.Province.toUpperCase(),
              "Vendor Payment Request Number",
              ": " + paymentRequest.Code,
            ]);
            header.push(["Vendor Name", ": " + supplier.Name, "", ""]);
            header.push([
              "Vendor Address",
              ": " +
                supplier.Address.toUpperCase() +
                "\n  " +
                supplier.SubDistrict.toUpperCase() +
                ", " +
                supplier.District.toUpperCase() +
                "\n  " +
                supplier.City.toUpperCase() +
                ", " +
                supplier.Province.toUpperCase(),
              "",
              "",
            ]);

            autoTable(doc, {
              startY: 30,
              head: [],
              body: header,
              margin: { right: 10, left: 10 },

              columnStyles: {
                0: {
                  fontSize: "9",
                  cellWidth: 30,
                  // fontStyle: 'bold'
                },
                1: {
                  fontSize: "9",
                  cellWidth: 80,
                  // fontStyle: 'bold'
                },
                2: {
                  fontSize: "9",
                  cellWidth: 30,
                  // fontStyle: 'bold'
                },
                3: {
                  fontSize: "9",
                  // fontStyle: 'bold'
                },
              },
            });

            // doc.text("Currency", 10, 78);
            // doc.text(": " + purchaseInvoice[0].purchaseInvoice.CurrencyCode, 40, 78);
            const head = [
              [
                "ID",
                "Doc Type",
                "Due Date",
                "Gross Amount",
                "Charges",
                "PPN",
                "PPH",
                "Total",
              ],
            ];
            // 'Freight Cost', 'PPH',
            let line = [];
            let total = parseFloat(0);
            let y = 95;
            let total_dn = parseFloat(0);
            let total_other = parseFloat(0);
            let total_amount = parseFloat(0);
            purchaseInvoice.map((v, i) => {
              line.push([
                i + 1,
                [
                  "Vendor Invoice Number : " +
                    v.purchaseInvoice.Code +
                    "\nVendor Billing : " +
                    v.purchaseInvoice.CodeInternal +
                    "\nVendor Tax Invoice Number : " +
                    v.purchaseInvoice.TaxReturnNumber,
                ],
                this.dateFormat(v.purchaseInvoice.DueDate),
                parseFloat(v.purchaseInvoice.ItemValue)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                parseFloat(v.purchaseInvoice.TotalCharge)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                parseFloat(v.purchaseInvoice.Tax)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                parseFloat(v.wh_tax)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                parseFloat(v.purchaseInvoice.TotalInvoiceValue)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              ]);
              let other = parseFloat(0);
              let dn = parseFloat(0);
              v.payment.map((subv) => {
                if (subv.Type == 2) {
                  dn += parseFloat(subv.PaidInvoiceValue);
                } else {
                  other += parseFloat(subv.PaidInvoiceValue);
                }
              });
              total_amount =
                total_amount +
                (v.purchaseInvoice.TotalInvoiceValue - other - dn);
              total_dn += parseFloat(dn);
              total_other += parseFloat(other);

              y = y + 20;
              total += v.proposal.PaidValue;
            });

            // if (dn > 0) {
            line.push([
              { content: "Debit Note ", colSpan: 7 },
              {
                content: total_dn
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                halign: "right",
              },
            ]);
            // y = y + 20;
            // }
            // if (other > 0) {
            line.push([
              { content: "Advance / Previous Payment", colSpan: 7 },
              total_other
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ]);
            y = y + 20;
            // }
            line.push([
              { content: "Total Amount ", colSpan: 7 },
              parseFloat(total_amount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ]);
            line.push([
              { content: "Outstanding Balance ", colSpan: 7 },
              parseFloat(total_amount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ]);
            line.push([
              { content: "Payment Request ", colSpan: 7 },
              parseFloat(total)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ]);
            // doc.text("Payment Type", 10, 82);
            // doc.text(": " + (total_amount == total ? "Full Payment" :"Partial Payment"), 40, 82);
            autoTable(doc, {
              startY: 83,
              head: [],
              margin: { right: 10, left: 10 },
              body: [
                [
                  "Currency ",
                  ": " + purchaseInvoice[0].purchaseInvoice.CurrencyCode,
                ],
                [
                  "Payment Type ",
                  ": " +
                    (total_amount == total
                      ? "Full Payment"
                      : "Partial Payment"),
                ],
              ],
              columnStyles: {
                0: {
                  fontSize: "10",
                  cellWidth: 35,
                },
                1: {
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
              },
              // theme: 'grid',
            });
            autoTable(doc, {
              startY: 100,
              head: head,
              body: line,
              margin: { right: 10, left: 10 },
              theme: "grid",
              drawCell: function (cell, data) {
                console.log(cell, data.column, "data.column");
                cell.styles.halign = "right";
                // if (data.column.dataKey === 'price' || data.column.dataKey === 'qty' || data.column.dataKey === 'price') {
                // }
              },
              didParseCell: function (cell, data) {
                // var col = data.column.index;
                // if (col==3 || col==5) {
                // data.cell.styles.halign = 'right';
                // }
              },
              columnStyles: {
                0: {
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                1: {
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                2: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                3: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                4: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                5: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                6: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
                7: {
                  halign: "right",
                  fontSize: "10",
                  // fontStyle: 'bold'
                },
              },
            });

            // y = y + 10
            // doc.text('Debit Note',120, y  ); //(text,x,y)
            // doc.text(': ',150, y  ); //(text,x,y)
            // doc.text(total_dn.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),190, y,{align:'right'}  ); //(text,x,y)
            // y = y + 5
            // doc.text('Payment Request',120, y  ); //(text,x,y)
            // doc.text(': ',150, y  ); //(text,x,y)
            // doc.text(total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),190, y,{align:'right'}  ); //(text,x,y)
            // y += 10

            doc.text("Transfer To", 10, 205); //(text,x,y)
            doc.setFont("times").setFontSize(fontSize); //.setFontStyle("bold");
            doc.text("Bank Name", 10, 210); //(text,x,y)
            doc.text(": " + selected.ToBankName, 50, 210); //(text,x,y)
            doc.text("Account Number", 10, 215); //(text,x,y)
            doc.text(": " + selected.ToAccountNumber, 50, 215); //(text,x,y)
            doc.text("Account Name", 10, 220); //(text,x,y)
            doc.text(": " + selected.ToAccountName, 50, 220); //(text,x,y)

            autoTable(doc, {
              startY: 230,
              head: [],
              body: [
                [
                  "Terbilang ",
                  ": " + angkaTerbilang(total).toUpperCase() + " RUPIAH",
                ],
              ],
              // theme: 'grid',
              didDrawCell: function (data) {},
            });

            doc.text("Prepared by", 10, 250); //(text,x,y)
            doc.text(this.$userLogin.name, 10, 270); //(text,x,y)
            doc.text("Acknowledge by", 60, 250); //(text,x,y)
            doc.text("Approve 1 by", 110, 250); //(text,x,y)
            doc.text("Approve 2 by", 160, 250); //(text,x,y)

            //   doc.text('text',140, y,{ align:'right'}) //(text,x,y)
            // // })
            //   doc.text('Created By',10, 250) //(text,x,y)
            //   doc.text(this.$userLogin.name,10, 270) //(text,x,y)
            //   doc.text('Approved By',80, 250) //(text,x,y)
            //   doc.text('Accepted By',150, 250) //(text,x,y)
            // img, type, x, y, w, l
            // doc.addPage()
            const pageCount = doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              // Go to page i
              doc.setPage(i);
              //Print Page 1 of 4 for example
              doc.text(
                "Page " + String(i) + " of " + String(pageCount),
                210 - 20,
                297 - 25,
                null,
                null,
                "right"
              );
            }
            doc.save("Payment-Instruction-" + selected.Code + ".pdf");

            console.log(params, "params");
            console.log("after print");
            this.$http
              .post(
                "/api/v1/purchase-payment-request/print/" + selected.ID,
                params
              )
              .then((r) => {
                this.reloadData(this.params);
              })
              .catch((e) => {
                // this.getData();
                this.reloadData(this.params);
              });
          } catch (e) {
            this.$vs.loading.close();
            console.log(e);
          }

          //console.log(result)
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
    handleCheck(tr) {
      this.selectedPrint = tr;
      const params = {
        purchase_payment_request_id: tr.ID,
        old_exchange_rate_id: tr.CurrencyExchangeRateID,
      };

      console.log(tr, "selected");
      // this.exchange = true;
      this.$vs.loading();
      console.log(params);
      this.$http
        .post("/api/v1/purchase-payment-request/check-exchange-rate", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            console.log(result.data.new);
            this.newExchange = result.data.new;
            console.log(this.newExchange, "ini disini");
            if (this.newExchange == null) {
              console.log("ini jalan");
              // this.handleSubmit();
              this.doDownloadPdf(tr);
            } else {
              console.log(this.exchange, "ini disini 2");
              this.oldExchangeRate = tr.Total / tr.CurrencyExchangeRate;
              this.newExchangeRate = tr.Total / result.data.new.ExchangeRate;
              this.newExchangeRateID = result.data.new.ID;
              this.totalNew = tr.Total * this.newExchangeRate;
              this.exchange = true;
            }
            // this.handleClose()
            // this.$vs.notify({
            //   title: "Success",
            //   text: "",
            //   color: "success",
            //   position: "top-right",
            //   iconPack: "feather",
            //   icon: "icon-check",
            // });
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    doDownloadPdf(selected) {
      this.selectedData = selected;
      if (selected.CountPrint > 0) {
        this.confirmation = true;
        return;
      }
      this.doByPassDownloadPdf(selected);
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    closeDetail() {
      this.activePrompt2 = false;
    },
    downloadPdf() {
      // this.$http
      //   .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
      //   .then(result => {
      //     // this.$vs.loading.close();
      //     result.data.purchasePaymentProposal.map( v => {
      //       this.paymentProposal.push(v)
      //     })
      //     this.notes = this.selected.Notes.toString().split(";")
      //     //console.log(result)
      //   })
      //   .catch(e => {
      //     this.$vs.loading.close();
      //   })
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-request", {
            params: {
              status: 2,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              start_payment_date: this.paymentDate.startDate
                ? this.formatDate(this.paymentDate.startDate)
                : null,
              end_payment_date: this.paymentDate.endDate
                ? this.formatDate(this.paymentDate.endDate)
                : null,
              bank_id:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.bank_id.toString(),
              bank_name:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.bank_name,
              branch_name:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.branch_name,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentRequest;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentRequest.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
