<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.purchasePaymentRequest"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template v-if="$store.getters['user/hasPermissions']('view')">
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Show Invoice" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                </div>
              </template>
              <p v-else>No Action</p>
            </vs-td>
            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td>
              {{ tr.ReferenceCode }}
            </vs-td>
            <vs-td> ({{ tr.SupplierCode }}) {{ tr.SupplierName }} </vs-td>
            <vs-td>
              {{
                tr.BankName +
                " - " +
                tr.AccountBranch +
                " (" +
                tr.AccountNumber +
                " " +
                tr.AccountName +
                ") "
              }}
            </vs-td>
            <vs-td>
              {{
                tr.ToBankName +
                " - " +
                tr.ToAccountBranch +
                " (" +
                tr.ToAccountNumber +
                " " +
                tr.ToAccountName +
                ") "
              }}
            </vs-td>
            <vs-td> {{ dateFormat(tr.ClearingDate) }}<br /> </vs-td>
            <vs-td>
              {{ priceFormat(tr.Total) }} ({{ tr.CurrencyExchangeCode }})<br />
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Information"
        color="primary"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="true"
        :active.sync="approvalPrompt"
      >
        <div class="con-exemple-prompt prompt-md">
          <div v-if="this.selectedData.Type !== 4">
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Code Internal"
                  name="Code Internal"
                  v-model="purchaseInvoice.CodeInternal"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Code External"
                  name="Code External"
                  v-model="purchaseInvoice.Code"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Item Value"
                  name="Item Value"
                  v-model="itemValue"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Tax Value"
                  name="Tax Value"
                  v-model="taxValue"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total"
                  name="Total"
                  v-model="total"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Paid"
                  name="Total Paid"
                  v-model="totalPaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Unpaid"
                  name="Total Unpaid"
                  v-model="totalUnpaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="No Shipment"
                  name="No Shipment"
                  v-model="purchaseInvoice.DeliveryOrderNumber"
                  readonly
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Supplier Name"
                  name="Vendor"
                  :value="cnSupplier.supplier_name"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Supplier Code"
                  name="Supplier Code"
                  :value="cnSupplier.supplier_code"
                  readonly
                />
              </div>
            </div>
            <div class="vx-row mb-2">
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Debit"
                  name="Total Debit"
                  :value="
                    priceFormat(
                      cnSupplier.total_debit ? cnSupplier.total_debit : 0
                    )
                  "
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Credit"
                  name="Total Credit"
                  :value="
                    priceFormat(
                      cnSupplier.total_credit ? cnSupplier.total_credit : 0
                    )
                  "
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Reference"
                  name="Reference"
                  :value="cnSupplier.reference"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total"
                  name="Total"
                  v-model="total"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Paid"
                  name="Total Paid"
                  v-model="totalPaid"
                  readonly
                />
              </div>
              <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="Total Unpaid"
                  name="Total Unpaid"
                  v-model="totalUnpaid"
                  readonly
                />
              </div>
              <!-- <div class="vx-col w-1/2">
                <vs-input
                  class="w-full input-readonly"
                  label="No Shipment"
                  name="No Shipment"
                  v-model="purchaseInvoice.DeliveryOrderNumber"
                  readonly
                />
              </div> -->
            </div>
          </div>
          <!-- <vs-divider style="width: 100%;"><b>Charge</b></vs-divider>
        <div class="vx-row ">
          <vs-table class="w-full" stripe border description :sst="true" :data="dataCharge">
            <template slot="thead">
              <vs-th>Charge Name</vs-th>
              <vs-th>Value</vs-th>
              <vs-th>Tax</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{
                      data[indextr].charge_name.replace(/-/g, " ")
                    }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].value }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].tax }}</span>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div> -->
          <vs-divider style="width: 100%"><b>Payment</b></vs-divider>
          <vs-table
            stripe
            border
            description
            class="w-full"
            :sst="true"
            :data="dataPayment"
            max-height="400px"
          >
            <template slot="thead">
              <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
              <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
              <vs-td style="text-align: right"><b>Paid</b></vs-td>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                :class="data[indextr].class"
              >
                <vs-td
                  style="text-align: center"
                  :data="data[indextr].BankName"
                >
                  {{
                    data[indextr].BankID == 0
                      ? data[indextr].Type == 2
                        ? "DN"
                        : "-"
                      : data[indextr].BankName +
                        " " +
                        data[indextr].AccountNumber
                  }}
                </vs-td>
                <vs-td style="text-align: center">
                  {{ data[indextr].ReferenceCode }}
                </vs-td>
                <vs-td style="text-align: right">
                  {{
                    data[indextr].PaidInvoiceValue.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div
            @detailPurchaseInvoice="handleDetailPurchaseInvoice"
            @close="handleClose"
            :selected="selectedData"
          />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue";
import moment from "moment";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Reference Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "Bank From",
          value: "bank_name",
        },
        {
          text: "Bank To",
          value: "to_bank_name",
        },
        {
          text: "Transaction Date",
          value: "clearing_date",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      itemValue: 0,
      totalPaid: 0,
      total: 0,
      totalUnpaid: 0,
      totalDiscount: 0,
      dpp: 0,
      taxValue: 0,
      purchaseInvoice: {},
      dataCharge: [],
      dataPayment: [],
      dataPaymentProposal: [],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      cnSupplier: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("DD/MM/YYYY");
      }
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    closeDetail() {
      this.activePrompt2 = false;
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-request", {
            params: {
              status: 3,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleDetailPurchaseInvoice(id) {
      this.approvalPrompt = true;
      this.$vs.loading();
      const url =
        this.selectedData.Type === 4
          ? "/api/v1/credit-note-supplier/line-for-proposal/"
          : "/api/v1/pi/payment-proposal-line/";
      this.$http.get(url + id).then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          if (this.selectedData.Type === 4) {
            this.cnSupplier = resp.data.credit_note_supplier;
            this.totalPaid = resp.data.total_paid
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            this.totalUnpaid = resp.data.unpaid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            this.purchaseInvoice = resp.data.purchase_invoice;
            this.dataCharge = resp.data.data_charge;
            this.dataPayment = resp.data.detail_payment;
            this.dataPaymentProposal = resp.data.payment_proposal_proses;
            this.totalPaid = resp.data.paid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.itemValue = resp.data.item_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_invoice_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            const whTax = resp.data.data_line.reduce((acc, item) => {
              if (this.purchaseInvoice.IsSkb === 1) {
                return acc;
              } else {
                return acc + item.wh_tax;
              }
            }, 0);

            this.totalUnpaid = (resp.data.unpaid_value - whTax)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.taxValue = resp.data.tax_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.totalDiscount = resp.data.total_discount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentRequest;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentRequest.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
